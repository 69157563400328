<template>
  <div class="row">
    <div class="card p-lg-10 p-5">
      <div>
        <h2 class="text-blue">Descargar informe</h2>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 col-sm-12 d-flex flex-column">
          <label>Seleccione una ubicación</label>
          <el-select
            v-model="sedeSelect"
            placeholder="Seleccione una sede"
            size="large"
            filterable
          >
            <el-option
              v-for="sede in ubicaciones"
              :key="sede._id"
              :label="sede.nombre"
              :value="sede._id"
            />
          </el-select>
        </div>
        <div class="col-md-6 col-sm-12 d-flex flex-column">
          <label>Seleccione un area</label>
          <el-select
            v-model="areaSelect"
            placeholder="Seleccione un area"
            size="large"
            filterable
          >
            <el-option
              v-for="area in areas"
              :key="area._id"
              :label="area.nombre"
              :value="area._id"
            />
          </el-select>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 col-sm-12 d-flex flex-column">
          <label>Seleccione un dispositivo</label>
          <el-select
            v-model="equipoSelect"
            placeholder="Seleccione un dispositivo"
            filterable
            size="large"
          >
            <el-option
              v-for="area in dispositivos"
              :key="area._id"
              :label="area.nombre"
              :value="area._id"
            />
          </el-select>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="row">
            <div class="col-md-6 col-sm-12 d-flex flex-column">
              <label>Fecha</label>
              <el-date-picker
                v-model="fecha"
                type="date"
                class="w-100"
                placeholder="Seleccione un dia"
              />
            </div>
            <div class="col-md-6 col-sm-12 d-flex flex-column">
              <label>Formato</label>
              <div class="pt-3">
                <el-radio v-model="tipo" label="1" size="large">PDF</el-radio>
                <el-radio v-model="tipo" label="2" size="large">Excel</el-radio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn bg-blue rounded-pill text-white px-20"
          type="button"
          @click="descargarInforme"
        >
          Generar informe
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import sedes from "@/services/sedes.service";
import equipos from "@/services/equipos.service";
// import reportes from "@/services/informes.service";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import axios from "axios";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import es from "dayjs/locale/es";
// import Swal from "sweetalert2";

export default defineComponent({
  name: "profile-informes",
  components: {},
  setup() {
    const ubicaciones = ref([]);
    const sedeSelect = ref("");
    const areas = ref([]);
    const areaSelect = ref("");
    const dispositivos = ref([]);
    const equipoSelect = ref("");
    const fecha = ref("");
    const tipo = ref("1");

    const traerUbicaciones = () => {
      sedes.listarSedes().then((res) => {
        ubicaciones.value = res.data;
      });
    };
    const traerAreasPorSedes = () => {
      sedes.listarAreas(sedeSelect.value).then((res) => {
        areas.value = [];
        areaSelect.value = "";
        equipoSelect.value = "";
        areas.value = res.data.areas;
      });
    };
    const traerEquiposPorAreas = () => {
      equipos
        .traerDispositivosPorAreasSinPaginar(areaSelect.value)
        .then((res) => {
          dispositivos.value = [];
          equipoSelect.value = "";
          dispositivos.value = res.data;
        });
    };
    const descargarInforme = () => {
      if (!equipoSelect.value) {
        Swal.fire("Error", "Debe seleccionar un equipo", "error");
        return;
      }
      if (!fecha.value) {
        Swal.fire("Error", "Debe seleccionar una fecha", "error");
        return;
      }
      const disp = dispositivos.value.find((d) => d._id === equipoSelect.value);
      if (!disp) {
        Swal.fire("Error", "No se encontro el equipo", "error");
        return;
      }
      const token = window.localStorage.getItem("id_token");
      const url = `${process.env.VUE_APP_MICROSERVICE_API_INFORMES}/dispositivo/${equipoSelect.value}/pdf?token=${token}`;
      const res = axios.getUri({
        url,
        params: {
          fecha: dayjs(fecha.value).format("YYYY-MM-DD"),
          tipo: parseInt(tipo.value),
          serial: disp.serial,
        },
      });
      window.open(res, "_blank");
    };
    watch(
      () => sedeSelect.value,
      () => {
        traerAreasPorSedes();
      }
    );
    watch(
      () => areaSelect.value,
      () => {
        traerEquiposPorAreas();
      }
    );
    onMounted(() => {
      traerUbicaciones();
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
    });
    return {
      ubicaciones,
      sedeSelect,
      areas,
      areaSelect,
      dispositivos,
      equipoSelect,
      fecha,
      tipo,
      descargarInforme,
    };
  },
});
</script>
